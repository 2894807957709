import React, { useMemo } from "react";
import ALink from "~/components/features/custom-link";
import { getPublicImageURL } from "~/utils/getPublicImageUrl";
import { connect } from "react-redux";
import { cartActions } from "~/store/cart";
import { modalActions } from "~/store/modal";
import { eventActions } from "~/store/events";
import { useRouter } from "next/router";
import { getProductInventory } from "~/utils/products";
import { alertToaster } from "~/utils/popupHelper";

const ProductVariant = ({ item, sizes, product, ...props }) => {
  const { addToCart, id } = props;

  let productId = id;

  const router = useRouter();

  const { listingPrice, price } = item;
  const save = useMemo(() => {
    if (listingPrice && listingPrice > price) {
      return Math.round(((listingPrice - price) * 100) / listingPrice);
    }
    return 0;
  }, [listingPrice, price]);

  const { hasInventory, currentInventory } = useMemo(
    () => getProductInventory(product, item.id),
    [item.id, sizes, product?.slug]
  );

  const addToCartHandler = (selectedVariant) => {
    if (hasInventory) {
      if (product.variants.items.length > 0) {
        const variant = product.variants.items.find(
          (i) => i.id === selectedVariant
        );

        if (variant) {
          const tmpName = `${item.title} - ${variant.title}`;
          const tmpPrice = variant.price;

          addToCart({
            ...product,
            name: tmpName,
            qty: 1,
            price: tmpPrice,
            variantId: selectedVariant,
          });

          router.push("/pages/cart");
        }
      }
    } else {
      alertToaster("Out of Stock", "error");
    }
  };

  // const validateAndGoToCheckout = useCallback(() => {
  //   onProceedToCheckout();

  //   if (user) {
  //     router.push("/pages/checkout");
  //     return true;
  //   }

  //   openLogin(true);
  //   return false;
  // }, [user, cartList]);

  const ImageTag = {
    3: "/images/best.png",
    2: "/images/top.png",
  };

  return (
    <div key={item.id}>
      <div
        className={`wbs-cta acv-multipack wrap ${
          item.size == 3 ? "best-value" : "rest-packs"
        } bg-white `}
        key={item.id}
      >
        <div>
          <img
            src={getPublicImageURL(item.images?.items[0]?.imageKey)}
            className={`wbs-cta-img image-sizes ${
              productId === "apple-cider-vinegar-face-wash" ||
              productId === "apple-cider-vinegar-face-wash-set2"
                ? " wrap ml-1"
                : ""
            }`}
          />
        </div>

        <div className="padding-extra">
          <h5 className="pack-title">{item.title}</h5>
          <p className="mb-0 line-height1-25">{item.description}</p>{" "}
        </div>
        <div>
          <h5 className="pack-title">₹{item.price}</h5>
          {item.listingPrice > item.price && (
            <>
              <del className="old-price mr-2">₹{item.listingPrice}</del>{" "}
            </>
          )}
          {save > 0 && <p className="acv-savings mb-0">{save}% OFF</p>}
        </div>
        {id === "apple-cider-vinegar-shampoo-set" ||
        "apple-cider-vinegar-shampoo-set-2" ||
        "rosemary-oil-shampoo-and-hair-conditioner-combo" ? (
          <ALink
            href="#"
            className="w-button wbs-button-primary buy-btn wrap"
            onClick={() => addToCartHandler(item.id)}
          >
            Buy {item.title}→{" "}
          </ALink>
        ) : (
          <ALink
            href="#"
            className="w-button wbs-button-primary buy-btn"
            onClick={() => addToCartHandler(item.id)}
          >
            Buy {item.size} →{" "}
          </ALink>
        )}
        {ImageTag[item.size] && (
          <img src={ImageTag[item.size]} className="img-tag" />
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    cartList: state.cart.data || [],
    user: state.user.data,
  };
}

export default connect(mapStateToProps, {
  addToCart: cartActions.addToCart,
  onProceedToCheckout: eventActions.proceedToCheckout,
  openLogin: modalActions.openPasswordlessModal,
  emptyCart: cartActions.emptyCart,
})(ProductVariant);
