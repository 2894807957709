import React, { useEffect } from "react";
import TagManager from "react-gtm-module";

import { GTM_ID } from "~/config";

export default function Scripts() {
  useEffect(() => {
    TagManager.initialize({ gtmId: GTM_ID });
  }, []);

  return <></>;
}
