import { connect } from "react-redux";

import ALink from "~/components/features/custom-link";

function Footer({ footer, store }) {
  const { name } = store || {};

  return (
    <footer className="footer">
      <div className="">
        <div className="footer-section">
          <div className="d-flex justify-content-center flex-wrap footer-gap">
            <ALink
              prefetch={false}
              target="_blank"
              href="https://www.buywow.in/p/contact-us"
            >
              Contact Us
            </ALink>
            <ALink
              prefetch={false}
              target="_blank"
              href="https://www.buywow.in/p/terms-of-service"
            >
              Terms Of Service
            </ALink>

            <ALink
              prefetch={false}
              target="_blank"
              href="https://www.buywow.in/p/privacy-policy"
            >
              Privacy Policy
            </ALink>
          </div>
          <p className="copyright ls-normal d-flex justify-content-center mt-4 copyright-text">
            {name} &copy; {new Date().getFullYear()}. All Rights Reserved
          </p>
        </div>
      </div>
    </footer>
  );
}

function mapStateToProps(state) {
  return {
    store: state.system.store,
  };
}

export default connect(mapStateToProps)(Footer);
