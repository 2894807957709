import { useEffect } from "react";
import { useRouter } from "next/router";
import Image from "next/image";
import { connect } from "react-redux";

import ALink from "~/components/features/custom-link";

import { headerBorderRemoveList } from "~/utils/data/menu";
import { modalActions } from "~/store/modal";
import useWindowDimensions from "~/utils/getWindowDimension";
import { scrollWithOffset } from "~/utils/helper";

function Header(props) {
  const { setCartVisibility, hasFaq } = props;
  const router = useRouter();
  const { cart } = router.query;

  const { isSmallSize: isMobile } = useWindowDimensions();

  useEffect(() => {
    let header = document.querySelector("header");
    if (header) {
      if (
        headerBorderRemoveList.includes(router.pathname) &&
        header.classList.contains("header-border")
      )
        header.classList.remove("header-border");
      else if (!headerBorderRemoveList.includes(router.pathname))
        document.querySelector("header").classList.add("header-border");
    }
  }, [router.pathname]);

  useEffect(() => {
    if (cart === "1") {
      setCartVisibility(true);
    }
  }, [cart]);

  return (
    <header className="header header-border d-header">
      <div className="fix-top sticky-content">
        <div className="header-middle">
          <div className="container">
            <div className="header-left header-left-grid">
              <ALink href="#" className="logo logo-wrap">
                <Image
                  src="/images/logo-acv.png"
                  alt="logo"
                  width={100}
                  height={100}
                  quality={100}
                  priority
                  objectFit="contain"
                />
              </ALink>

              {!isMobile && (
                <div className="menu-tab">
                  <ALink
                    href="#"
                    onClick={() => {
                      scrollWithOffset("benefits", 10, (ele) => {
                        ele.click();
                      });
                    }}
                  >
                    BENEFITS
                  </ALink>
                  <ALink
                    href="#"
                    onClick={() => {
                      scrollWithOffset("reviews", 10, (ele) => {
                        ele.click();
                      });
                    }}
                  >
                    REVIEWS
                  </ALink>
                  <ALink
                    href="#"
                    onClick={() => {
                      scrollWithOffset("ingredients", 10, (ele) => {
                        ele.click();
                      });
                    }}
                  >
                    INGREDIENTS
                  </ALink>
                  {hasFaq && (
                    <ALink
                      href="#"
                      onClick={() => {
                        scrollWithOffset("faq", 100, (ele) => {
                          ele.click();
                        });
                      }}
                    >
                      FAQ
                    </ALink>
                  )}
                  <ALink
                    href="https://www.buywow.in/p/contact-us"
                    target={"blank"}
                  >
                    CONTACT US
                  </ALink>
                  <ALink
                    href="https://www.buywow.in/p/about-us"
                    target={"blank"}
                  >
                    ABOUT US
                  </ALink>
                </div>
              )}
            </div>

            {!isMobile && (
              <div className="header-right">
                {/* <ALink href="/pages/wishlist" className="wishlist  mr-3 ">
                <Heart />
              </ALink>
              <span className="divider"></span> */}
                <ALink
                  href="#"
                  className="button-12 w-button"
                  onClick={() => {
                    scrollWithOffset("product-details", -8, (ele) => {
                      ele.click();
                    });
                  }}
                >
                  BUY NOW
                </ALink>

                {/* <ALink href="/pages/account" className="account wishlist mr-2">
                <User />
              </ALink> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}

function mapStateToProps(state) {
  return {
    auth: !!state.user.data,
  };
}

export default connect(mapStateToProps, {
  openPasswordLess: modalActions.openPasswordlessModal,
  setCartVisibility: modalActions.setCartVisibility,
})(Header);
